var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-flex md-layout-item md-size-100"},[_c('file-view-handler',{attrs:{"options":_vm.options},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uploadedFiles = ref.uploadedFiles;
return [_c('file-upload-component-handler',{ref:"fileUpload",attrs:{"options":Object.assign({}, _vm.options,
          {publicFeature: 'Register/User',
          type: 'Single',
          uploadedFileId:
            uploadedFiles && uploadedFiles.length > 0 && uploadedFiles[0].id})},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var uploadFiles = ref.uploadFiles;
        var handleFilesSelected = ref.handleFilesSelected;
return [_c('image-upload',{staticClass:"profile-images",attrs:{"defaultSrc":_vm.defaultSrc,"uploadedImage":_vm.getImageFromFiles(uploadFiles, uploadedFiles)},on:{"change":handleFilesSelected,"onClick":function($event){_vm.handleShowImagePreview(
              _vm.getImageFromFiles(uploadFiles, uploadedFiles)
            )}}})]}}],null,true)})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }