<template>
  <div class="md-card">
    <div class="md-card-header">
      <div class="card-icon">
        <i class="fa fa-globe"></i>
      </div>
      <h4 class="title">{{ getTitle() }}</h4>
    </div>
    <div class="md-card-content">
      <div class="md-layout-item">
        <patient-form></patient-form>
      </div>
    </div>
  </div>
</template>
<script>
import PatientPage from "./ManagePatientPage.vue";
export default {
  inject: ["managePatient"],
  components: {
    "patient-form": PatientPage,
  },
  methods: {
    getTitle() {
      if (this.managePatient.id) return "Edit Patient";
      else return "New Patient";
    },
  },
};
</script>
