<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex md-layout-item md-size-100">
      <file-view-handler :options="options" v-slot="{ uploadedFiles }">
        <file-upload-component-handler
          ref="fileUpload"
          :options="{
            ...options,
            publicFeature: 'Register/User',
            type: 'Single',
            uploadedFileId:
              uploadedFiles && uploadedFiles.length > 0 && uploadedFiles[0].id,
          }"
          v-slot="{ uploadFiles, handleFilesSelected }"
        >
          <image-upload
            class="profile-images"
            :defaultSrc="defaultSrc"
            @change="handleFilesSelected"
            :uploadedImage="getImageFromFiles(uploadFiles, uploadedFiles)"
            @onClick="
              handleShowImagePreview(
                getImageFromFiles(uploadFiles, uploadedFiles)
              )
            "
          ></image-upload>
        </file-upload-component-handler>
      </file-view-handler>
    </div>
  </div>
</template>

<script>
import ImageUpload from "../../controls/upload/imageUpload.vue";
import FileUploadComponentHandler from "../../../handlers/common-handlers/FileUploadComponentHandler";
import FileViewHandler from "../../../handlers/common-handlers/FileViewHandler";
export default {
  props: ["defaultSrc", "options", "getFileUploadRef"],
  inject: ["handleShowImagePreview"],
  components: {
    ImageUpload,
    FileUploadComponentHandler,
    FileViewHandler,
  },
  methods: {
    getImageFromFiles(uploadFiles, uploadedFiles) {
      if (uploadFiles && uploadFiles.length > 0) return uploadFiles[0].link;
      else if (uploadedFiles && uploadedFiles.length > 0)
        return uploadedFiles[0].link;
    },
  },
  mounted() {
    this.$emit("getFileUploadRef", this.$refs.fileUpload);
  },
};
</script>
