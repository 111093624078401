<template>
  <div>
    <div>
      <label>{{ label }}</label>
      <md-radio
        v-model="localValue"
        :required="required"
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        >{{ option.text }}</md-radio
      >
    </div>
    <div>
      <span class="md-error" style="color: #ff1744">{{ error }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "RadioButtonInput",
  props: {
    label: String,
    options: Array,
    value: [Number, String],
    error: String,
    required: Boolean,
    autofocus: Boolean,
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
